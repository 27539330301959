import React from 'react';
import {DeliverySection} from '@wix/delivery-section-checkout/widget';
import {DisplayState} from '@wix/delivery-section-checkout/types';

export enum DeliverySectionWrapperDataHook {
  root = 'DeliverySectionWrapperDataHook.root',
}

export const DeliverySectionWrapper = () => {
  return <DeliverySection displayState={DisplayState.EDITABLE} />;
};
